import Button from 'components/forms/button';
import useHasMounted from 'hooks/use-has-mounted';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import styles from './error-page.module.scss';

interface Props {
  errCode: number;
}

const ErrorPage: FC<Props> = ({ errCode }) => {
  const router = useRouter();
  const hasMounted = useHasMounted();

  const backToSearchform = () => {
    const aid = router.query['aid'] || '';
    window.location.href = `/?aid=${aid}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.countdownWrapper}>
          {hasMounted && (
            <CountdownCircleTimer
              isPlaying
              duration={10}
              size={100}
              strokeWidth={2}
              colors="#006dcc"
              onComplete={backToSearchform}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          )}
        </div>

        <div>
          <h1 className={styles.title}>
            {errCode === 404 ? 'Page not found' : 'An error occurred'}
          </h1>

          <p className={styles.subTitle}>
            We have noticed that an error occured. We will fix it as soon as possible
          </p>

          <p className={styles.message}>
            <strong>Please wait until beeing redirected to the previous step.</strong>
            <br />
            If redirection will not happen automatically please click link below:
          </p>

          <Button variant="secondary" onClick={backToSearchform}>
            Back to search form
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
