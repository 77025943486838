import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import ErrorPage from 'modules/error-page';
import { log } from 'src/logger';

const Custom404 = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    log({
      level: 'WARNING',
      tag: 'pageNotFound',
      msg: '404 - unknown page',
      data: { url: asPath }
    });
  }, []);
  return <ErrorPage errCode={404} />;
};

export default Custom404;
